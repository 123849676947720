import { Controller } from "@hotwired/stimulus";
import Choices from 'choices.js'


export default class extends Controller {
  connect() {
    if (!this.element.choicesInstance) {
      let innerClasses = ['choices__inner'];
      if (this.element.classList.contains('is-invalid')) {
        innerClasses.push('choices__is-invalid');
      } else if (this.element.classList.contains('is-valid')) {
        innerClasses.push('choices__is-valid');
      }

      this.element.choicesInstance = new Choices(this.element, {
        placeholder: true,
        removeItemButton: true,
        classNames: {
          containerInner: innerClasses
        }
      });
    }
  }
}
